var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cosl":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-card',[_c('v-card-text',[_c('v-date-picker',{model:{value:(_vm.filters.date),callback:function ($$v) {_vm.$set(_vm.filters, "date", $$v)},expression:"filters.date"}})],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.stats,"items-per-page":50,"group-by":"Категория","dense":""},scopedSlots:_vm._u([{key:"item.stats.same_mega_group_percent",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((item.stats.same_mega_group / item.stats.users * 100).toFixed(2))+"% ")]}},{key:"item.stats.banned_percent",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((item.stats.banned / item.stats.users * 100).toFixed(2))+"% ")]}},{key:"item.stats.paid_percent",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((item.stats.paid / item.stats.users * 1000).toFixed(2))+"% ")]}},{key:"item.stats.no_coins_percent",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((item.stats.no_coins / item.stats.users * 1000).toFixed(2))+"% ")]}}])})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }