<template>
  <v-container>
    <v-row>
      <v-col cosl="12">
        <v-row>
          <v-col cols="4">
            <v-card>
              <v-card-text>
                <v-date-picker
                  v-model="filters.date"
                ></v-date-picker>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-card>
              <v-card-text>
                <v-data-table
                  :headers="headers"
                  :items="stats"
                  :items-per-page="50"
                  group-by="Категория"
                  dense
                >
                  <template v-slot:item.stats.same_mega_group_percent="{ item }">
                    {{(item.stats.same_mega_group / item.stats.users * 100).toFixed(2)}}%
                  </template>
                  <template v-slot:item.stats.banned_percent="{ item }">
                    {{(item.stats.banned / item.stats.users * 100).toFixed(2)}}%
                  </template>
                  <template v-slot:item.stats.paid_percent="{ item }">
                    {{(item.stats.paid / item.stats.users * 1000).toFixed(2)}}%
                  </template>
                  <template v-slot:item.stats.no_coins_percent="{ item }">
                    {{(item.stats.no_coins / item.stats.users * 1000).toFixed(2)}}%
                  </template>
                </v-data-table>
              </v-card-text>

            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import store from "@/store";
import {computed, ref} from "@vue/composition-api";
import { mapGetters } from 'vuex'

export default {
  name: "RetStats",
  setup() {
    store.dispatch("retention/loadStats");
    const headers = ref([
      {text: 'Категория', value: 'Категория'},
      {text: 'Группа', value: 'group'},
      {text: 'Всего', value: 'stats.users', cellClass: 'border-right'},
      {text: 'Ост', value: 'stats.same_mega_group'},
      {text: '%', value: 'stats.same_mega_group_percent', cellClass: 'border-right'},
      {text: 'Бан', value: 'stats.banned'},
      {text: '%', value: 'stats.banned_percent', cellClass: 'border-right'},
      {text: 'Опл', value: 'stats.paid'},
      {text: '‰', value: 'stats.paid_percent', cellClass: 'border-right'},
      {text: 'Зак', value: 'stats.no_coins'},
      {text: '%', value: 'stats.no_coins_percent'},

    ])
    const filters = ref({
      // date:'2023-04-26'
      date: new Date().toISOString().substr(0, 10)
    })
    const allStats = computed(() => store.getters["retention/getStats"])
    const stats = computed(() => {
      const s = getStatsByDate(filters.value.date)
      return s
    })

    const getStatsByDate = date =>{
      const result = []

      // перебираем все группы
      for (const group of allStats.value) {
        const filteredStats = group.stats.filter(stat => stat.date === date)
        console.log('filtered stats', filteredStats)

        if (filteredStats.length > 0) {
          result.push({
            group: group.group,
            Категория: group.mega_group,
            stats: filteredStats[0]
          })
        }
      }

      return result
    }

    return {
      allStats, stats, headers,
      filters,
    };
  },
}
</script>

<style scoped>
/*.pub-table .v-table { border: none; border-collapse: collapse !important; }*/
/deep/ tbody td,th {
  /*border: 1px solid #ddd !important;*/
  padding: 0 5px !important;
  text-align: start;
}
.v-data-table td.border-right {
  border-right: 1px solid #ccc;
}
/deep/ .border-right {
  border-right: 1px solid #ddd !important;
}
/*!*table td:first-child { border-left: none; }*!*/
/*.v-data-table>.v-data-table__wrapper > table > tbody > tr > td {*/
/*  padding: 0 40px;*/
/*}*/
.ps {
  height: 80%;
}
</style>
